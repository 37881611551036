import {Box, CircularProgress, Container, Paper, TextField,} from "@mui/material";
import {useEffect, useState} from "react";
import {fetchFromApi} from "../helpers/fetchFromApi";
import {fetchViaPost} from "../helpers/fetchViaPost";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {STATI} from "../helpers/stati";
import {canEvaluate} from "../helpers/canEvaluate";
import {canCompile} from "../helpers/canCompile";
import enLocale from "date-fns/locale/en-GB";
import {FixturesFooter} from "./FixturesFooter";
import {FixturesToolbar} from "./FixturesToolbar";
import {fixtureColumns} from "../parts/fixtureColumns";
import {FixtureDetails} from "./FixtureDetails";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

export function FixturesOverview({leagues, user}) {
  const strs = [
    "bWFyY3VzLWJiZ0B3ZWIuZGU=",
    "cGF0cmlrLnNjaG1pZHRAeW1haWwuY29t",
    "dGlwc3RlcmNvbXBhbnlAZ214LmRl",
    "dG9ta2x1ZXNlbmVyQHQtb25saW5lLmRl",
    "ZGVubmlzb21lbHRzY2hlbmtvQHdlYi5kZQ==",
    "emFzdGVyNjBAb25saW5lLmRl",
    "Zi5zcGllZ2VsQGhvdG1haWwuZGU=",
    "bWhlaWRidWVjaGVsQG1haWwuZGU=",
    "aGVpbWFubi5oQGFvbC5jb20=",
    "c3RlZmZlbndlaWNrLjFAZ214LmRl",
    "dGFzY2hlbGVlci50bWxAZ29vZ2xlbWFpbC5jb20=",
    "eWFubmljZ295bkBnbWFpbC5jb20=",
    "amVucy5zY2hlbmtlQGdvb2dsZW1haWwuY29t",
    "a3Vuei5zdDNmYW5AZ21haWwuY29t",
    "eWFubmljZ295bjU3QGdtYWlsLmNvbQ==",
    "bWlvczc4QGdteC5kZQ==",
    "dW5kZXJncm91bmRzYWFyQGFvbC5jb20=",
    "dGhvbWFzLmJ1c2xAZ21haWwuY29t",
    "dHJhaW5lcjhAZ214LmRl",
    "YnNiYXNzYm9vc3RlZEBnbWFpbC5jb20=",
    "cGF1bGVAcGF1bGUtd2V0dGVuLmRl",
    "WWFzaW4tU2NocmVpYmVyQHdlYi5kZQ==",
    "cXVlbnRpbi5oZXJyZW5icnVlY2tAZ214Lm5ldA==",
    "ZGF2aWQuYm90c2NoZW5AZ21haWwuY29t",
    "anVhbm1hc3NhbmV0QGdteC5kZQ==",
    "cm9iZGVtYXJjbzk1QGxpYmVyby5pdA==",
    "cy5tb2VsbGVyNzlAZ21haWwuY29t",
    "Y2hyaXN0aWFuaGlsY2hlckBmcmVlbmV0LmRl",
    "bXIubW9saUB3ZWIuZGU=",
    "bGllc2tlLmJlbml0ekB0LW9ubGluZS5kZQ==",
    "ZGVtb2huQG91dGxvb2suZGU="
  ];

  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);

  const [selectionModel, setSelectionModel] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState();
  const [startDate, setStartDate] = useState(
    new Date(sessionStorage.getItem("startDate") ?? new Date())
  );

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    agl: false
  });

  const [mode, setMode] = useState("compile");
  const [forceCompile, setForceCompile] = useState(false);
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState(
    []
  );

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const handleModeChange = (event, newMode) => {
    if (newMode === null) return;
    setMode(newMode);
    setSelectionModel([]);
    setForceCompile(false);
  };

  useEffect(() => {
    async function fetchData() {
      if (typeof user !== "undefined" && strs.includes(btoa(user.id))) {
        setColumnVisibilityModel({agl: true});
      }
      setLoading(true);
      var request
      try {
        request = await fetchFromApi(
          `fetch-data/fixtures/between/${startDate.toISOString().split("T")[0]}/${
            startDate.toISOString().split("T")[0]
            // }?include=localTeam,visitorTeam,odds`
          }?include=localTeam,visitorTeam`
        );
      } catch (e) {
        //console.log(e)
      }

      const response = await request.json();

      if (response.meta.pagination.links.next) {
        try {
          const page2 = await (
            await fetchFromApi(
              `fetch-data/fixtures/between/${
                startDate.toISOString().split("T")[0]
              }/${
                startDate.toISOString().split("T")[0]
              }?include=localTeam,visitorTeam&page=2`
            )
          ).json();
          response.data = [...response.data, ...page2.data];
        } catch (e) {
          //console.log(e)
        }
      }

      const requestFixtures = response.data.map(
        ({id, league_id, localteam_id, visitorteam_id}) => ({
          id,
          league_id,
          localteam_id,
          visitorteam_id,
        })
      );
      const details_response = await fetchViaPost(
        "get-details-for-fixtures",
        JSON.stringify({fixtures: requestFixtures})
      );
      const details = await details_response.json();

      var fixtures = response.data.map((e) => ({
        ...e,
        ...details.find((d) => d.id === e.id),
      }));

      let foundLeagues = leagues.map(a => a.id);
      let cleanedFixtures = fixtures.filter((f) => foundLeagues.includes(f.league_id));
      fixtures = cleanedFixtures;

      const rows = fixtures.map((fixture) => {
        const {name, country, user_id} = leagues.find((l) => l.id === fixture.league.id) ?? {};
        return {
          ...fixture,
          details: {
            date: new Date(fixture.time.starting_at.timestamp * 1000),
            id: fixture.id,
          },
          league: {name, country},
          user: {user},
          localTeam: {
            ...fixture.localTeam,
            custom_name: fixture.localTeam?.data?.name
          },
          visitorTeam: {
            ...fixture.visitorTeam,
            custom_name: fixture.visitorTeam?.data?.name
          },
          ...fixture.probs,
        };
      });
      setRows(rows);
      setLoading(false);
    }

    if (leagues?.length) fetchData(user);
  }, [startDate, leagues]);

  async function handleCompileConfirm() {
    setLoading(true);

    async function compileFixture(slice) {
      let league = rows.filter((e) => slice.includes(e.id))[0];
      setModalTitle("Warning!")
      setModalText("You have to initialize the league \"" + league.league.name + "\" before you can compile fixtures. Go to team settings to do so.")
      /*var response = {}
      try {*/
      var response = await (
        await fetchViaPost(
          "compile-fixtures-in-selection",
          JSON.stringify({fixture_ids: slice})
        )
      ).json();
      /*} catch (e) {
        response = {success: false}
      }*/
      return response;
    }

    try {
      const SLICE_LENGTH = 40;
      const numberOfSlices = Math.floor(selectionModel.length / SLICE_LENGTH);
      for (let i = 0; i <= numberOfSlices; i++) {
        const slice = selectionModel.slice(
          i * SLICE_LENGTH,
          (i + 1) * SLICE_LENGTH
        );
        const response = await compileFixture(slice);
        if (response.success) {
          setRows((r) =>
            r.map((e) => {
              if (slice.includes(e.id)) {
                return {
                  ...e,
                  status: STATI["compiled"],
                  ...(response.result.find((o) => o.fixture_id === e.id)
                    ?.probs ?? {}),
                };
              }
              return e;
            })
          );
        } else {
          setShowModal(true)
        }
      }
      setSelectionModel([]);
    } catch {
    }
    setLoading(false);
  }

  async function handleRecompile(
    fixture_id,
    season_id,
    team_strength_1,
    team_strength_2
  ) {
    const response = await fetchViaPost(
      "compile-odds-for-one-fixture-from-team-strengths",
      JSON.stringify({
        fixture_id,
        season_id,
        team_strength_1,
        team_strength_2,
      })
    );
    const body = await response.json();
    setRows(
      rows.map((e) => {
        if (e.id === fixture_id) {
          return {
            ...e,
            status: STATI["compiled"],
            goalexp: {
              home: body.homegoalexp,
              away: body.awaygoalexp,
            },
            ...body.probs,
          };
        }
        return e;
      })
    );
  }

  async function handleEvaluateConfirm() {
    setLoading(true);
    try {
      const SLICE_LENGTH = 10;
      const numberOfSlices = Math.floor(selectionModel.length / SLICE_LENGTH);
      for (let i = 0; i <= numberOfSlices; i++) {
        const slice = selectionModel.slice(
          i * SLICE_LENGTH,
          (i + 1) * SLICE_LENGTH
        );
        const response = await fetchViaPost(
          "update-parameters",
          JSON.stringify({fixture_ids: slice})
        );
        const body = await response.json();
        if (body.success){
          console.log(rows);
          // setRows((r) =>
          //   r.map((e) => {
          //     if (selectionModel.includes(e.id)) {
          //       return {...e, status: STATI["evaluation confirmed"]};
          //     }
          //     return e;
          //   })
          // );
        }
      }
      setSelectionModel([]);
      setLoading(false);
      setShowModal(true);
      setModalTitle("Evaluation")
      setModalText("Finished");
    } catch {
    }
  }

  function getDetailPanelContent(props) {
    return <FixtureDetails {...props} handleRecompile={handleRecompile} user={user}/>;
  }

  if (loading)
    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress/>
      </Box>
    );

  function leadZero(num) {
    return String(num).padStart(2, '0')
  }

  return (
    <Container maxWidth="xl" style={{height: "100%"}}>
      {
        modalText !== "" ? <Modal
          open={showModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {modalTitle}
            </Typography>
            <Typography id="modal-modal-description" sx={{mt: 2}}>
              {modalText}
            </Typography>
          </Box>
        </Modal> : ""
      }

      <Box
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1em",
          marginBottom: "1em",
        }}
        title="Select the date"
      >
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={enLocale}
        >
          {}
          <div>
            <DatePicker
              label="Date"
              value={startDate}
              onChange={(startDate) => {
                startDate.setHours(23);
                setStartDate(startDate);
                sessionStorage.setItem("startDate", startDate.toISOString());
              }}
              renderInput={(params) => <TextField {...params} />}
            /></div>
        </LocalizationProvider>
        Matches found: {rows.length}
      </Box>

      <Box
        sx={{
          display: "flex",
          height: "75vh",
          "& .row--expanded": {
            bgcolor: (theme) => "rgb(255,181,99,0.2)",
          },
        }}
        component={Paper}
      >
        <div style={{flexGrow: 1}}>
          {!!rows?.length && (
            <DataGridPro
              onDetailPanelExpandedRowIdsChange={setDetailPanelExpandedRowIds}
              getRowClassName={(params) =>
                `row--${
                  detailPanelExpandedRowIds.includes(params.row.id)
                    ? "expanded"
                    : "collapsed"
                }`
              }
              getDetailPanelHeight={() => "auto"}
              getDetailPanelContent={getDetailPanelContent}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
              checkboxSelection
              disableSelectionOnClick
              isRowSelectable={({row}) =>
                (mode === "compile" &&
                  canCompile(row.status) &&
                  ["NS", "POSTP", "TBA"].includes(row.time.status)) ||
                forceCompile ||
                (mode === "evaluate" &&
                  canEvaluate(row) &&
                  (row.time.status === "FT" || row.time.status === "FT_PEN" || row.time.status === "AET"))
              }
              components={{
                Toolbar: () => (
                  <FixturesToolbar
                    mode={mode}
                    handleModeChange={handleModeChange}
                    forceCompile={forceCompile}
                    setSelectionModel={setSelectionModel}
                    setForceCompile={setForceCompile}
                  />
                ),
                Footer: () => (
                  <FixturesFooter
                    selectionModel={selectionModel}
                    mode={mode}
                    handleCompileConfirm={handleCompileConfirm}
                    handleEvaluateConfirm={handleEvaluateConfirm}
                  />
                ),
              }}
              rows={rows}
              columns={fixtureColumns}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) => {
                if (typeof user !== "undefined" && strs.includes(btoa(user.id))) {
                  setColumnVisibilityModel(newModel);
                } else {
                  newModel.agl = false
                  setColumnVisibilityModel(newModel);
                }
              }}
            />
          )}
        </div>
      </Box>
    </Container>
  );
}
