import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  FormControlLabel,
  Grid,
  IconButton,
  Slide,
  Slider,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import {AppBar} from "../styled-components/AppBar";
import {forwardRef, useEffect, useState} from "react";
import {fetchViaPost} from "../helpers/fetchViaPost";
import {styled} from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import YoutubeModal from "./YoutubeModal";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function UserDialog({open, setOpen, user, allUsers, setAllUsers}) {

  const strs = [
    "bWFyY3VzLWJiZ0B3ZWIuZGU=",
    "cGF0cmlrLnNjaG1pZHRAeW1haWwuY29t",
    "dGlwc3RlcmNvbXBhbnlAZ214LmRl",
    "dG9ta2x1ZXNlbmVyQHQtb25saW5lLmRl",
    "ZGVubmlzb21lbHRzY2hlbmtvQHdlYi5kZQ==",
    "emFzdGVyNjBAb25saW5lLmRl",
    "Zi5zcGllZ2VsQGhvdG1haWwuZGU=",
    "bWhlaWRidWVjaGVsQG1haWwuZGU=",
    "aGVpbWFubi5oQGFvbC5jb20=",
    "c3RlZmZlbndlaWNrLjFAZ214LmRl",
    "dGFzY2hlbGVlci50bWxAZ29vZ2xlbWFpbC5jb20=",
    "eWFubmljZ295bkBnbWFpbC5jb20=",
    "amVucy5zY2hlbmtlQGdvb2dsZW1haWwuY29t",
    "a3Vuei5zdDNmYW5AZ21haWwuY29t",
    "eWFubmljZ295bjU3QGdtYWlsLmNvbQ==",
    "bWlvczc4QGdteC5kZQ==",
    "dW5kZXJncm91bmRzYWFyQGFvbC5jb20=",
    "dGhvbWFzLmJ1c2xAZ21haWwuY29t",
    "dHJhaW5lcjhAZ214LmRl",
    "YnNiYXNzYm9vc3RlZEBnbWFpbC5jb20=",
    "WWFzaW4tU2NocmVpYmVyQHdlYi5kZQ==",
    "cXVlbnRpbi5oZXJyZW5icnVlY2tAZ214Lm5ldA==",
    "ZGF2aWQuYm90c2NoZW5AZ21haWwuY29t",
    "anVhbm1hc3NhbmV0QGdteC5kZQ==",
    "cm9iZGVtYXJjbzk1QGxpYmVyby5pdA==",
    "cy5tb2VsbGVyNzlAZ21haWwuY29t",
    "Y2hyaXN0aWFuaGlsY2hlckBmcmVlbmV0LmRl",
    "bXIubW9saUB3ZWIuZGU=",
    "bGllc2tlLmJlbml0ekB0LW9ubGluZS5kZQ==",
    "ZGVtb2huQG91dGxvb2suZGU="
  ];

  const handleChangeTutorialActive = (event) => {
    localStorage.setItem("showTutorials", event.target.checked);
    setTutorialActive(event.target.checked);
  };

  async function syncUsers() {
    if (!user) {
      setAllUsers([]);
      return
    }
    if (btoa(user.id) === "YmF0eXIubWFsaWtAZ21haWwuY29t" || btoa(user.id) === "WWFzaW4tU2NocmVpYmVyQHdlYi5kZQ==" || btoa(user.id) === "cGF1bGVAcGF1bGUtd2V0dGVuLmRl") {
      const data = await (
        await fetchViaPost(
          "get-all-users",
          JSON.stringify({})
        )
      ).json();
      setAllUsers(data.users);
    } else {
      setAllUsers([])
    }
  }

  async function getUserToken(userId) {
    const data = await (
      await fetchViaPost(
        "get-user-token",
        JSON.stringify({search_user_id: userId})
      )
    ).json();
    var win = window.open(data, '_blank');
    win.focus();
  }

  function handleClose() {
    setOpen(false);
  }

  function handleLogout() {
    document.location = "/sign-in";
  }

  async function handleSave(e) {
    e?.preventDefault?.();

    const data = new FormData(e.currentTarget);

    const settings = {
      ...(data.get("name") === user.name ? {} : {name: data.get("name")}),
      mainUpdateFactor: noMainUpdateFactor
        ? Number.MAX_SAFE_INTEGER
        : -data.get("mainUpdateFactor"),
      drawUpdateFactor: noDrawUpdateFactor
        ? Number.MAX_SAFE_INTEGER
        : -data.get("drawUpdateFactor"),
    };

    await fetchViaPost("save-settings", JSON.stringify(settings));

    setOpen(false);
  }

  const [isDisabled, setIsDisabled] = useState(false);
  const [tutorialActive, setTutorialActive] = useState(localStorage.getItem("showTutorials") === "true");
  const [noMainUpdateFactor, setNoMainUpdateFactor] = useState(false);
  const [noDrawUpdateFactor, setNoDrawUpdateFactor] = useState(false);

  useEffect(() => {
    syncUsers();
    setNoMainUpdateFactor(user?.main_update_factor === Number.MAX_SAFE_INTEGER);
    setNoDrawUpdateFactor(user?.draw_update_factor === Number.MAX_SAFE_INTEGER);
  }, [user]);

  if (!(user && user.main_update_factor && user.draw_update_factor))
    return <></>;

  const Div = styled('div')(({theme}) => ({
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
  }));

  function getCurrentPackage(userId) {
    if (["cGF1bGVAcGF1bGUtd2V0dGVuLmRl"].includes(btoa(userId))) {
      return "GOLD";
    }
    if (strs.includes(btoa(userId)))
      return "SILVER";
    else
      return "BRONZE";
  }

  function printPaypalSubscriptionText() {
    if (getCurrentPackage(user.id) === "FREE") {
      return <><br/>Please <a
        href="https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-30B45991F14096518MYE4UTQ"
        target={"_blank"}>Subscribe</a> to
        upgrade your package</>;
    } else
      return <></>;
  }

  // From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <form onSubmit={handleSave}>
        <AppBar sx={{position: "relative"}}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon/>
            </IconButton>
            <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5em",
                }}
              >
                Settings
              </div>
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleLogout}
              aria-label="Logout"
              sx={{mr: 5}}
            >
              <LogoutIcon/>
            </IconButton>
            <Button autoFocus color="inherit" type="submit">
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <Container maxWidth="sm">
          <Box sx={{mt: 1}}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              defaultValue={user?.name}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="id"
              label="Email"
              name="id"
              defaultValue={user?.id}
              disabled
            />
            <Box component="fieldset" sx={{px: 8, py: 2}}>
              <legend>Account:</legend>
              <Div>{"Your current package is : "}<span style={{
                color: "#00f",
                fontWeight: "900",
                fontSize: "1.2em"
              }}>{getCurrentPackage(user.id)}</span>{printPaypalSubscriptionText()}</Div>
              <Div>
                <FormControlLabel
                  control={<Checkbox defaultChecked onChange={handleChangeTutorialActive} checked={tutorialActive}/>}
                  label="Show tutorial videos"/>
              </Div>
            </Box>
            <Box component="fieldset" sx={{px: 8, py: 2, textAlign: '-webkit-center'}}
                 style={(btoa(user.id) === "YmF0eXIubWFsaWtAZ21haWwuY29t" || btoa(user.id) === "WWFzaW4tU2NocmVpYmVyQHdlYi5kZQ==" || btoa(user.id) === "cGF1bGVAcGF1bGUtd2V0dGVuLmRl") ? {display: 'block'} : {display: 'none'}}>
              <legend>Administration:</legend>
              <div>
                <YoutubeModal title="Evaluate" youtubeId="eVMMCRPPeMs"></YoutubeModal>
                <YoutubeModal title="Compilation mode" youtubeId="abwRW_wUOzE"></YoutubeModal>
              </div>
              <Button
                disabled={isDisabled}
                type="button"
                onClick={async () => {
                  setIsDisabled(true);
                  await fetchViaPost("share-team_properties", JSON.stringify({"user_id_to_skip": "paule@paule-wetten.de"}))
                  setIsDisabled(false);
                }}
                fullWidth
                style={(btoa(user.id) !== 'YmF0eXIubWFsaWtAZ21haWwuY29t') ? {display: 'none'} : {display: 'block'}}
                variant="contained"
                sx={{mb: 2}}
              >
                Sync team-properties for all users
              </Button>
              <Autocomplete
                id="country-select-demo"
                sx={{width: 300}}
                options={allUsers}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                    {/*<img
                      loading="lazy"
                      width="20"
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      alt=""
                    />*/}
                    {option.label}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Find user token"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
                onChange={(event, value) => getUserToken(value.label)}
              />
            </Box>

            <Box component="fieldset" sx={{px: 8, py: 2}}>
              <legend>Update factors</legend>
              <Typography
                title="There is in every game a difference between the goal expectancies you had and the result the game produced. By this difference we can adjust the strengths. But we have to decide how much bearing we want this difference to take? A recommended value is 30 (which translates into impact of 1/30). You can still adjust this value to your liking between maximum impact (of 1/10; more makes no sense really) and minimum impact (of 1/100). You can also decide to go for the „no impact“ option. This would mean: you know the strengths best yourself and if you spot something goes wrong or you change your assessment, you manually adjust via the „team settings“ menu.">
                How much impact do you figure the last result should have on the
                team settings?
              </Typography>
              <Slider
                id="mainUpdateFactor"
                label="Main update factor"
                name="mainUpdateFactor"
                sx={{mt: 4}}
                min={-100}
                max={-10}
                defaultValue={-user.main_update_factor}
                required
                valueLabelDisplay="on"
                marks={[
                  {value: -10, label: "Max impact"},
                  {value: -100, label: "Min impact"},
                ]}
                valueLabelFormat={(value) =>
                  `1/${-value} (${(100 / -value).toFixed(2)} %)`
                }
                disabled={noMainUpdateFactor}
              />
              <FormControlLabel
                control={
                  <Checkbox /* checked={checked} onChange={handleChange} */ />
                }
                label="No impact"
                id="noMainUpdateFactor"
                name="noMainUpdateFactor"
                checked={noMainUpdateFactor}
                onChange={(e) => setNoMainUpdateFactor(e.target.checked)}
              />
              <Typography
                sx={{mt: 4}}
                title="There is a standard setting of 60 (which means 1/60). It is a pretty sensible value found by rather „guessing“. Any game that ends in a draw will thus slightly raise the draw probability while any non-drawn game will lower it. There is a very fair balance between these two, but the effect will be such, that if a team play „too many draws“ (more than expected) the draw probability will simply be higher (not by much, though), while if they play less draws than expected it will be lowered. Both make sense, just stick to the preset value — or experiment with it if you are unhappy somewhere."
              >
                How much impact do you figure a drawn result should have on the
                probability for the draw in upcoming fixtures?
              </Typography>
              <Slider
                id="drawUpdateFactor"
                label="Draw update factor"
                name="drawUpdateFactor"
                sx={{mt: 4}}
                min={-100}
                max={-30}
                defaultValue={-user.draw_update_factor}
                required
                valueLabelDisplay="on"
                marks={[
                  {value: -30, label: "Max impact"},
                  {value: -100, label: "Min impact"},
                ]}
                valueLabelFormat={(value) =>
                  `1/${-value} (${(100 / -value).toFixed(2)} %)`
                }
                disabled={noDrawUpdateFactor}
              />
              <FormControlLabel
                control={
                  <Checkbox /* checked={checked} onChange={handleChange} */ />
                }
                label="No impact"
                id="noDrawUpdateFactor"
                name="noDrawUpdateFactor"
                checked={noDrawUpdateFactor}
                onChange={(e) => setNoDrawUpdateFactor(e.target.checked)}
              />
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{mt: 3, mb: 2}}
            >
              Save
            </Button>
            <Grid
              container
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            ></Grid>
          </Box>
        </Container>
      </form>
    </Dialog>
  );
}
