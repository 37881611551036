import {Component} from "react";
import {Table, TableBody, TableHead, TableRow} from "@mui/material";
import * as PropTypes from "prop-types";

export class LeagueTable extends Component {
  render() {
    if(this.props.stats.length === 0) {
      return null;
    }
    return <Table>
      <TableHead>
        <th colSpan={2} align="left" style={{width: "50%"}}>
          League table
        </th>
        <th align="left">SP</th>
        <th align="left">S</th>
        <th align="left">U</th>
        <th align="left">N</th>
        <th align="left">Goals</th>
        <th align="left">Diff</th>
        <th align="left">Points</th>
      </TableHead>
      <TableBody>
        <TableRow>
          <th>{this.props.stats[this.props.localteamId].league.position}</th>
          <td>{this.props.localTeam.custom_name}</td>
          <td>{this.props.stats[this.props.localteamId].league.games}</td>
          <td>{this.props.stats[this.props.localteamId].league.won}</td>
          <td>{this.props.stats[this.props.localteamId].league.draw}</td>
          <td>{this.props.stats[this.props.localteamId].league.lost}</td>
          <td>{this.props.stats[this.props.localteamId].league.goals}</td>
          <td>{this.props.stats[this.props.localteamId].league.diff}</td>
          <td>{this.props.stats[this.props.localteamId].league.points}</td>
        </TableRow>

        <TableRow>
          <th>{this.props.stats[this.props.visitorteamId].league.position}</th>
          <td>{this.props.visitorTeam.custom_name}</td>

          <td>{this.props.stats[this.props.visitorteamId].league.games}</td>
          <td>{this.props.stats[this.props.visitorteamId].league.won}</td>
          <td>{this.props.stats[this.props.visitorteamId].league.draw}</td>
          <td>{this.props.stats[this.props.visitorteamId].league.lost}</td>
          <td>{this.props.stats[this.props.visitorteamId].league.goals}</td>
          <td>{this.props.stats[this.props.visitorteamId].league.diff}</td>
          <td>{this.props.stats[this.props.visitorteamId].league.points}</td>
        </TableRow>
        <TableRow>
          <th colSpan={2} align="left" style={{width: "50%"}}>
            Home table
          </th>
          <th align="left">SP</th>
          <th align="left">S</th>
          <th align="left">U</th>
          <th align="left">N</th>
          <th align="left">Goals</th>
          <th align="left">Diff</th>
          <th align="left">Points</th>
        </TableRow>
        <TableRow>
          <th>{this.props.stats[this.props.localteamId].home.position}</th>
          <td>{this.props.localTeam.custom_name}</td>

          <td>{this.props.stats[this.props.localteamId].home.games}</td>
          <td>{this.props.stats[this.props.localteamId].home.won}</td>
          <td>{this.props.stats[this.props.localteamId].home.draw}</td>
          <td>{this.props.stats[this.props.localteamId].home.lost}</td>
          <td>{this.props.stats[this.props.localteamId].home.goals}</td>
          <td>{this.props.stats[this.props.localteamId].home.diff}</td>
          <td>{this.props.stats[this.props.localteamId].home.points}</td>
        </TableRow>
        <TableRow>
          <th colSpan={2} align="left" style={{width: "50%"}}>
            Away table
          </th>
          <th align="left">SP</th>
          <th align="left">S</th>
          <th align="left">U</th>
          <th align="left">N</th>
          <th align="left">Goals</th>
          <th align="left">Diff</th>
          <th align="left">Points</th>
        </TableRow>
        <TableRow>
          <th>{this.props.stats[this.props.visitorteamId].away.position}</th>
          <td>{this.props.visitorTeam.custom_name}</td>

          <td>{this.props.stats[this.props.visitorteamId].away.games}</td>
          <td>{this.props.stats[this.props.visitorteamId].away.won}</td>
          <td>{this.props.stats[this.props.visitorteamId].away.draw}</td>
          <td>{this.props.stats[this.props.visitorteamId].away.lost}</td>
          <td>{this.props.stats[this.props.visitorteamId].away.goals}</td>
          <td>{this.props.stats[this.props.visitorteamId].away.diff}</td>
          <td>{this.props.stats[this.props.visitorteamId].away.points}</td>
        </TableRow>
      </TableBody>
    </Table>;
  }
}

LeagueTable.propTypes = {
  stats: PropTypes.arrayOf(PropTypes.any),
  localteamId: PropTypes.any,
  localTeam: PropTypes.func,
  visitorteamId: PropTypes.any,
  visitorTeam: PropTypes.func
};
