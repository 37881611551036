import AwaitingCompilationIcon from "@mui/icons-material/HourglassEmpty";
import CompiledIcon from "@mui/icons-material/Addchart";
import CompilationConfirmedIcon from "@mui/icons-material/InsertChart";
import AwaitingEvaluationIcon from "@mui/icons-material/GppMaybe";
import EvaluationConfirmedIcon from "@mui/icons-material/CheckCircle";
import HelpIcon from "@mui/icons-material/Help";
import {OddsTooltip} from "../components/OddsTooltip";
import {STATI} from "../helpers/stati";
import {GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GRID_DETAIL_PANEL_TOGGLE_FIELD,} from "@mui/x-data-grid-pro";
import {IconButton} from "@mui/material";
import ExpandIcon from "@mui/icons-material/Expand";
import {extractFlag} from "../components/Help";
import AsianHandicapMenu from "./AsianHandicapMenu";
import {fetchFromApi} from "../helpers/fetchFromApi";

function CustomDetailPanelToggle() {
  return (
    <IconButton aria-label="expand" title="expand to see fixture details">
      <ExpandIcon/>
    </IconButton>
  );
}

async function getCountryRequest(country_id) {
  const response = await (
    await fetchFromApi(`fetch-country/${country_id}`)
  ).json();
  return response.data
}

export const fixtureColumns = [
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    headerName: "Expand",
    type: "string",
    field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
    renderCell: (params) => <CustomDetailPanelToggle {...params} />,
  },
  {
    headerName: "status",
    field: "status",
    flex: 0.5,
    renderCell: ({value}) => {
      const map = {
        "awaiting compilation": AwaitingCompilationIcon,
        compiled: CompiledIcon,
        "compilation confirmed": CompilationConfirmedIcon,
        "awaiting evaluation": AwaitingEvaluationIcon,
        "evaluation confirmed": EvaluationConfirmedIcon,
      };
      const map2 = Object.keys(map).reduce(
        (prev, cur) => ({
          ...prev,
          [STATI[cur]]: {icon: map[cur], title: cur},
        }),
        {}
      );
      const {icon: Icon, title} = map2[value] ?? {
        icon: HelpIcon,
        title: "no status",
      };
      return <Icon titleAccess={title}/>;
    },
  },
  {
    headerName: "kickoff time",
    field: "details",
    flex: 1,
    renderCell: ({value}) => (
      <div title={value.id}>
        <div>
          {value.date.toLocaleDateString("en-GB", {
            weekday: "short",
            day: "numeric",
            month: "numeric",
          })}{" "}
          {value.date.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })}
        </div>
      </div>
    ),
  },
  {
    headerName: "competition",
    field: "league",
    flex: 1,
    renderCell: ({value}) => {
      if (typeof value.name !== "undefined") {
        return (
          <div style={{display: "flex", alignItems: "center", gap: "0.5em"}}>
            <img
              style={{width: "1em"}}
              alt={value.country.data.name}
              title={value.country.data.name}
              src={`data:image/svg+xml;utf8,${encodeURIComponent(
                extractFlag(value.country.data.extra)
              )}`}
            />
            {value.name}
          </div>
        );
      }
    },
  },
  {
    headerName: "home team",
    field: "localTeam",
    flex: 1.5,
    renderCell: ({value}) => (
      <div
        onMouseEnter={(event) => {
          getCountryRequest(value.data.country_id).then((response) => {
            event.target.title=response.official_name;
          })
        }}
        style={{display: "flex", alignItems: "center", gap: "0.5em"}}>
        <img src={value.data.logo_path} style={{height: "1.5em"}} alt=""/>
        {value.custom_name}
      </div>
    ),
  },
  {
    headerName: "away team",
    field: "visitorTeam",
    flex: 1.5,
    renderCell: ({value}) => (
      <div
        onMouseEnter={(event) => {
          getCountryRequest(value.data.country_id).then((response) => {
            event.target.title=response.official_name;
          })
        }}
        style={{display: "flex", alignItems: "center", gap: "0.5em"}}>
        <img src={value.data.logo_path} style={{height: "1.5em"}} alt=""/>
        {value.custom_name}
      </div>
    ),
  },
  {
    headerName: "result",
    description: "Fixture result after game is finished. Could also be live result.",
    field: "scores",
    flex: "1 0 auto",
    renderCell: ({value}) => {
      let htScore = value.ht_score;
      if (htScore === null) {
        return (<span>-</span>);
      }
      let ftScore = value.ft_score;
      let etScore = value.et_score;
      let psScore = value.ps_score;
      let firstScore = "-";
      if (ftScore === null) {
        firstScore = "- (" + htScore + ")";
      } else {
        firstScore = ftScore + " (" + htScore + ")";
      }
      if (etScore !== null) {
        firstScore += " (" + etScore + ")";
      }
      if (psScore !== null) {
        firstScore += " (" + psScore + ")";
      }
      return (<span>{firstScore}</span>);

    },
  },
  {
    headerName: "true home",
    field: "homewin",
    description: "true price for home win. Reciprocal of win probability home side",
    flex: 1,
    renderCell: ({value, row}) => (
      <OddsTooltip
        oddsId={row.id}
        label="1"
      >
        <span>{value ? (1 / value).toFixed(2) : "-"}</span>
      </OddsTooltip>
    ),
  },
  {
    headerName: "true draw",
    field: "draw",
    description: "true price for the draw. Reciprocal of draw probability",
    flex: 1,
    renderCell: ({value, row}) => (
      <OddsTooltip
        oddsId={row.id}
        label="X"
      >
        <span>{value ? (1 / value).toFixed(2) : "-"}</span>
      </OddsTooltip>
    ),
  },
  {
    headerName: "true away",
    field: "awaywin",
    description: "true price for away win. Reciprocal of away win probability",
    flex: 1,
    renderCell: ({value, row}) => (
      <OddsTooltip
        oddsId={row.id}
        label="2"
      >
        <span>{value ? (1 / value).toFixed(2) : "-"}</span>
      </OddsTooltip>
    ),
  },
  {
    headerName: "Extras",
    field: "agl",
    description: "See more for that fixture",
    flex: 1,
    renderCell: ({row}) => <AsianHandicapMenu row={row}/>,
  }
];
